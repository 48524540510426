import { createNavigation } from 'next-intl/navigation'
import { defineRouting } from 'next-intl/routing'
type TPathnames = Record<string, string>

export const routing = defineRouting({
  locales: ['ar', 'en'],
  defaultLocale: 'ar',
  pathnames: {
    '/': '/',
  } as TPathnames,
})

export type Pathnames = keyof typeof routing.pathnames | any
export type Locale = (typeof routing.locales)[number]

export const { Link, getPathname, redirect, usePathname, useRouter } =
  createNavigation(routing)
