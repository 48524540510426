'use client'
import { Link } from '@/i18n/routing'
import { Typography } from '@/ds/atoms/Typography'
import { Button } from '@/ds/atoms/Button'
import { NavigationItem } from './navigation'
import { RequestDemo } from './RequestDemo'
import { useLocale } from 'next-intl'
import { NavListSubItem } from './NavListSubItem'

interface MobileNavDropdownProps {
  item: NavigationItem
  onClose: () => void
}

export const MobileNavDropdown = ({
  item,
  onClose,
}: MobileNavDropdownProps) => {
  const locale = useLocale()
  return (
    <>
      {item.groups && (
        <div className="py-2 flex-col flex gap-6">
          {item.groups.map(group => (
            <div key={group.id}>
              {group.label && (
                <>
                  <Typography
                    text={group.label}
                    variant="body20"
                    className="text-primary-dark mb-3 pb-3 border-b border-grays-bg"
                  />
                </>
              )}
              <div className="flex flex-col gap-6">
                {group.items.map(groupItem => (
                  <Link
                    hrefLang={locale}
                    key={groupItem.id}
                    href={groupItem.path as '/' | { pathname: '/' }}
                    className="flex items-start group/item"
                    onClick={onClose}
                    target={groupItem.target}
                  >
                    <NavListSubItem item={groupItem} />
                  </Link>
                ))}
              </div>
            </div>
          ))}
          {item.cta && (
            <Button
              hrefLang={locale}
              variant={item.cta.btnVariant}
              color="primary"
              withIcon
              label={item.cta.btnText}
              href={item.cta.link}
              target={item.cta.target}
              className="w-fit"
            />
          )}
          {item.requestDemoProps && (
            <RequestDemo
              title={item.requestDemoProps.title}
              description={item.requestDemoProps.description}
              buttonText={item.requestDemoProps.cta.btnText}
              buttonHref={item.requestDemoProps.cta.link}
            />
          )}
        </div>
      )}
    </>
  )
}
