'use client'
import { ChevronDown } from 'lucide-react'
import { UrlObject } from 'url'
import { Link } from '@/i18n/routing'
import { NavGroup } from './navigation'
import { cn } from '@/ds/utils/cn'
import { Typography } from '@/ds/atoms/Typography'
import { RequestDemo } from './RequestDemo'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import NavigationDropdownTabs from './NavigationDropdownSections'
import { colorClasses } from './Navbar'
import { useLocale } from 'next-intl'
import { NavListSubItem } from './NavListSubItem'

type NavDropdownProps = {
  label: string
  groups: NavGroup[]
  className?: string
  requestDemoProps?: {
    title: string
    description: string
    cta: {
      btnText: string
      target?: '_self' | '_blank' | '_parent' | '_top'
      link: string
      btnVariant: ButtonVariant
    }
  }
  variant?: 'columns' | 'section'
  navbarColor?: 'dark' | 'light'
  cta?: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
}

const NavDropdown = ({
  label,
  groups,
  className,
  requestDemoProps,
  variant = 'columns',
  navbarColor = 'light',
  cta,
}: NavDropdownProps) => {
  const locale = useLocale()
  return (
    <details
      className={cn('group/nav peer', className)}
      onMouseEnter={e => {
        const details = e.currentTarget
        details.setAttribute('open', 'true')
      }}
      onMouseLeave={e => {
        const relatedTarget = e.relatedTarget as HTMLElement
        // Only close if not moving to the dropdown content
        if (
          relatedTarget?.closest &&
          !relatedTarget?.closest('.dropdown-content')
        ) {
          const details = e.currentTarget
          details.removeAttribute('open')
        }
      }}
    >
      <summary className="flex w-fit h-[72px] max-w-full">
        <div
          className={cn(
            'flex items-center gap-1.5 px-5 py-1 list-none cursor-pointer',
            colorClasses[navbarColor].text
          )}
        >
          <Typography
            text={label}
            variant="body16"
          />
          <ChevronDown
            className={cn(
              'w-4 h-4 transition-transform duration-200',
              'group-hover/nav:-rotate-180',
              'group-open:-rotate-180'
            )}
          />
        </div>
      </summary>

      <div
        className={cn(
          'fixed top-[72px] left-0 right-0 w-full max-w-[1536px] mx-auto'
        )}
      >
        <div
          className={cn(
            'absolute  start-[90px] md:start-[90px]',
            ' bg-white rounded-lg p-8',
            'animate-in fade-in slide-in-from-top-2 duration-200 mx-auto',
            'container flex gap-8 w-fit min-w-[850px]',
            'shadow'
          )}
        >
          <div className="flex flex-col gap-8 w-fit">
            {variant === 'columns' ? (
              <div className="flex gap-8 w-fit">
                {groups.map((group, index) => (
                  <div
                    key={group.id}
                    className={cn(
                      'min-w-[350px]',
                      group.items?.[0]?.description ? 'w-[400px]' : 'w-[350px]'
                    )}
                  >
                    {group.label && (
                      <>
                        <Typography
                          text={group.label}
                          variant="body20"
                          className="text-primary-dark mb-3"
                        />
                        <div className="h-[1px] w-full bg-grays-bg" />
                      </>
                    )}
                    <div className="flex flex-col gap-6 mt-3 items-start">
                      {group.items.map(item => (
                        <Link
                          key={item.id}
                          hrefLang={locale}
                          href={
                            item.path as
                              | '/'
                              | ({ pathname: '/' } & Omit<
                                  UrlObject,
                                  'pathname'
                                >)
                          }
                          className="flex items-start rounded-lg group/item"
                          target={item.target}
                        >
                          <NavListSubItem item={item} />
                        </Link>
                      ))}
                      {index === groups.length - 1 && cta && (
                        <Button
                          variant="onlyText"
                          color="primary"
                          withIcon
                          hrefLang={locale}
                          label={cta.btnText}
                          href={cta.link}
                          target={cta.target}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>{groups[0] && <NavigationDropdownTabs group={groups[0]} />}</>
            )}
          </div>
          {requestDemoProps && (
            <RequestDemo
              title={requestDemoProps.title}
              description={requestDemoProps.description}
              buttonText={requestDemoProps.cta.btnText}
              buttonHref={requestDemoProps.cta.link}
            />
          )}
        </div>
      </div>
    </details>
  )
}

export default NavDropdown
