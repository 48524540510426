/* eslint-disable react/prop-types */
const ChevronRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    {...props}
  >
    <g id="chevron-right">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9107 4.41058C7.23614 4.08514 7.76378 4.08514 8.08921 4.41058L13.0892 9.41058C13.4147 9.73602 13.4147 10.2637 13.0892 10.5891L8.08921 15.5891C7.76378 15.9145 7.23614 15.9145 6.9107 15.5891C6.58527 15.2637 6.58527 14.736 6.9107 14.4106L11.3214 9.99984L6.9107 5.58909C6.58527 5.26366 6.58527 4.73602 6.9107 4.41058Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default ChevronRightIcon
