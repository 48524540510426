import React from 'react'
import { Typography } from '@/ds/atoms/Typography'
import { Button } from '@/ds/atoms/Button'
import { cn } from '@/ds/utils/cn'

type RequestDemoProps = {
  title: string
  description: string
  buttonText: string
  buttonHref: string
  className?: string
  buttonTarget?: '_self' | '_blank' | '_parent' | '_top'
}

export const RequestDemo = ({
  title,
  description,
  buttonText,
  buttonHref,
  buttonTarget = '_self',
  className = '',
}: RequestDemoProps) => {
  return (
    <section className={cn('flex items-center', className)}>
      <div className=" rounded-s shadow-block container w-[335px] md:w-[453px] px-3 pt-3 pb-5">
        <div>
          <Typography
            variant="body20"
            className="mb-3 text-grays"
            text={title}
          />

          <Typography
            variant="body16"
            className="mb-4 text-grays-lighter"
            text={description}
          />

          <Button
            as="a"
            href={buttonHref}
            color="primary"
            variant="filled"
            label={buttonText}
            target={buttonTarget}
            withIcon
            className="text-base"
          />
        </div>
      </div>
    </section>
  )
}
