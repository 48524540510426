import React from 'react'
import { Button } from '@/ds/atoms/Button'
import { Typography } from '@/ds/atoms/Typography'
import type { NavGroup } from './navigation'
import { Link } from '@/i18n/routing'
import { UrlObject } from 'url'
import { useLocale } from 'next-intl'
import { NavListSubItem } from './NavListSubItem'

const NavigationDropdownTabs = ({ group }: { group: NavGroup }) => {
  const locale = useLocale()
  return (
    <div className="w-[914px] h-fit">
      {group.label && (
        <Typography
          variant="header3"
          className="mb-2 text-grays"
          text={group.label}
          as="p"
        />
      )}
      {group.description && (
        <Typography
          variant="body16"
          className="mb-3 text-grays-lighter"
          text={group.description}
        />
      )}
      {group.cta && (
        <Button
          as="a"
          href={group.cta.link}
          variant={group.cta.btnVariant || 'onlyText'}
          color="primary"
          label={group.cta.btnText}
          withIcon
          className="mb-5"
          target={group.cta.target}
          hrefLang={locale}
        />
      )}
      {group.label && <div className="h-[1px] w-full bg-grays-bg mb-5" />}
      <div className="flex flex-wrap gap-8">
        {group?.items.map(item => (
          <Link
            key={item.id}
            href={
              item.path as
                | '/'
                | ({ pathname: '/' } & Omit<UrlObject, 'pathname'>)
            }
            className="flex items-start rounded-lg group/item w-[280px]"
            target={item.target}
            hrefLang={locale}
            locale={locale}
          >
            <NavListSubItem item={item} />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default NavigationDropdownTabs
