'use client'
import React from 'react'
import { GlobeIcon as Globe } from 'lucide-react'
import { useLocale } from 'next-intl'
// import { useRouter } from '@/i18n/routing'
import { cn } from '@/ds/utils/cn'

function LocalSwitch({ navbarColor }: { navbarColor: string }) {
  const locale = useLocale()
  // const router = useRouter()
  // const pathname = usePathname()
  // const params = useParams()

  const onLocaleChange = () => {
    // const nextLocale = locale === 'en' ? 'ar' : 'en'
    const newRoute = window.location.href?.includes(
      process.env.NEXT_PUBLIC_FE_URL + '/ar'
    )
      ? window.location.href?.replaceAll(
          process.env.NEXT_PUBLIC_FE_URL + '/ar',
          process.env.NEXT_PUBLIC_FE_URL + '/en'
        )
      : window.location.href?.replaceAll(
          process.env.NEXT_PUBLIC_FE_URL + '/en',
          process.env.NEXT_PUBLIC_FE_URL + '/ar'
        )
    window.location.href = newRoute
    // startTransition(() => {
    //   router.replace(
    //     // @ts-expect-error -- TypeScript will validate that only known `params`
    //     // are used in combination with a given `pathname`. Since the two will
    //     // always match for the current route, we can skip runtime checks.
    //     { pathname, params },
    //     { locale: nextLocale }
    //   )
    // })
  }
  return (
    <button
      className="focus:outline-none"
      onClick={onLocaleChange}
    >
      <span className="flex gap-1">
        <span
          className={cn(
            navbarColor === 'light' ? 'text-primary' : 'text-white'
          )}
        >
          {locale === 'ar' ? 'English' : 'عربي'}
        </span>
        <Globe
          className={cn(
            'h-6 w-6',
            navbarColor === 'light' ? 'text-primary' : 'text-white'
          )}
        />
      </span>
    </button>
  )
}

export default LocalSwitch
