import { Link } from '@/i18n/routing'
import { Typography } from '@/ds/atoms/Typography'
import { Button } from '@/ds/atoms/Button'
import { NavGroup } from './navigation'
import { useLocale } from 'next-intl'
import { NavListSubItem } from './NavListSubItem'

interface MobileNavTabsDropdownProps {
  group: NavGroup
  onClose: () => void
}

export const MobileNavTabsDropdown = ({
  group,
  onClose,
}: MobileNavTabsDropdownProps) => {
  const locale = useLocale()
  return (
    <div className="py-2 flex-col flex gap-6">
      <div key={group.id}>
        {group.label && (
          <Typography
            text={group.label}
            variant="header3"
            className="text-grays"
          />
        )}
        {group.description && (
          <Typography
            text={group.description}
            variant="body14"
            className="text-grays-lighter mt-1"
          />
        )}
        {group.cta && (
          <Button
            hrefLang={locale}
            variant={group.cta.btnVariant}
            color="primary"
            withIcon
            label={group.cta.btnText}
            href={group.cta.link}
            target={group.cta.target}
            className="w-fit mt-3"
          />
        )}

        {group.items && (
          <div className="flex flex-col gap-6 mt-8">
            {group.items.map(groupItem => (
              <Link
                hrefLang={locale}
                key={groupItem.id}
                href={groupItem.path as '/' | { pathname: '/' }}
                className="flex items-start group/item"
                onClick={onClose}
                target={groupItem.target}
              >
                <NavListSubItem item={groupItem} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
