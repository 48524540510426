import React, { FC, ReactNode } from 'react'
import { cn } from '@/ds/utils/cn'

const colorStyles = {
  default: 'text-[#202939]',
  primary: 'text-[#2353F5]',
  white: 'text-[#fff]',
  bold: 'text-[#364152]',
  light: 'text-[#4B5565]',
  lighter: 'text-[#697586]',
  red: 'text-[#F04437]',
  green: 'text-[#12B669]',
}
type TextColor = keyof typeof colorStyles
export interface TypographyProps {
  variant?:
    | 'header1'
    | 'header2'
    | 'header3'
    | 'header4'
    | 'body20'
    | 'body20Bold'
    | 'body16'
    | 'body16Bold'
    | 'body14'
    | 'body12'
  className?: string
  text: string | ReactNode
  as?: 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  textColor?: TextColor
}

const Typography: FC<
  TypographyProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  variant = 'body16',
  className,
  text,
  as: Component,
  textColor,
  ...restProps
}) => {
  const variantClasses: Record<TypographyProps['variant'], string> = {
    header1: cn(
      'font-display text-[32px]/[35.2px] md:text-[72px]/[79.2px] font-bold'
    ),
    header2: cn(
      'font-display text-[32px]/[38.4px] md:text-[56px]/[67.2px] font-bold'
    ),
    header3: cn(
      'font-display text-[28px]/[36.4px] md:text-[32px]/[41.6px] font-normal'
    ),
    header4: cn(
      'font-display text-[24px]/[31.2px] md:text-[24px]/[31.2px] font-normal'
    ),
    body20: cn('font-display text-xl font-normal'),
    body20Bold: cn('font-display text-xl font-bold'),
    body16: cn('font-display text-base font-normal'),
    body16Bold: cn('font-display text-base font-bold'),
    body14: cn('font-display text-sm/[21px] font-normal'),
    body12: cn('font-display text-xs/[18px] font-normal'),
  }

  const componentVariantMap = {
    header1: 'h1',
    header2: 'h2',
    header3: 'h3',
    header4: 'h4',
  }

  const ComponentToRender = Component || componentVariantMap[variant] || 'p'

  return (
    <ComponentToRender
      className={cn(variantClasses[variant], colorStyles[textColor], className)}
      {...restProps}
    >
      {text}
    </ComponentToRender>
  )
}

export default Typography
