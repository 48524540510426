import { getCookie } from './getCookie'
import { getParamsUTM } from './getUTMParams'
import { useLocale } from 'next-intl'

const useAnalyticVariable = () => {
  const guest_id_jisr = getCookie('guest_id_jisr')
  const UTMParams = getParamsUTM()
  const locale = useLocale()

  return {
    guest_id_jisr,
    UTMParams,
    locale,
  }
}

export default useAnalyticVariable
