'use client'
import MobileSidebar from './MobileSidebar'
import { Link } from '@/i18n/routing'
import LocaleSwitch from '../LocaleSwitch/LocaleSwitch'
import { cn } from '@/ds/utils/cn'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import { NavigationItem } from './navigation'
import NavDropdown from './NavDropdown'
import { Typography } from '@/ds/atoms/Typography'
import { UrlObject } from 'url'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'
import { useLocale } from 'next-intl'
import Image from 'next/image'
import { Container } from '@/ds/atoms/Container'
import { useEffect, useState } from 'react'

export type NavHeaderProps = {
  logoWhite: StrapiImageComponent
  logoPrimary: StrapiImageComponent
  cta?: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
  navigationItems: NavigationItem[]
  navbarColor?: 'dark' | 'light'
  logInCta: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
}

export const colorClasses = {
  light: {
    text: 'text-grays-bold hover:text-grays-lighter',
  },
  dark: {
    text: 'text-white hover:text-white-80%',
  },
}

const Navbar = ({
  logoWhite,
  logoPrimary,
  cta,
  navigationItems,
  navbarColor: initialColor,
  logInCta,
}: NavHeaderProps) => {
  const locale = useLocale()
  const [navbarColor, setNavbarColor] = useState(initialColor)

  const logoColor = navbarColor === 'light' ? logoPrimary : logoWhite

  useEffect(() => {
    const handleNavbarColorChange = (event: CustomEvent) => {
      setNavbarColor(event.detail)
    }

    window.addEventListener('navbarColorChange', handleNavbarColorChange)

    return () => {
      window.removeEventListener('navbarColorChange', handleNavbarColorChange)
    }
  }, [])

  return (
    <nav
      className={cn(
        'w-full absolute top-0 left-0 right-0 z-50',
        'h-[56px] mt-4',
        'bg-transparent',
        colorClasses[navbarColor].text
      )}
    >
      <Container className="h-full mx-auto !py-0 md:!py-0 lg:!py-0">
        <div className="flex items-center justify-between h-full">
          <div className="lg:hidden w-full">
            <MobileSidebar
              items={navigationItems}
              cta={cta}
              logo={logoPrimary}
              logInCta={logInCta}
              // navbarColor={navbarColor}
            />
          </div>

          <div className="hidden lg:flex w-full items-center justify-between h-full">
            <div className="flex gap-8 w-[254px]">
              <Link
                href="/"
                hrefLang={locale}
                className="flex-shrink-0"
              >
                <div className="relative h-7 w-[59px] md:h-8 md:w-13">
                  <Image
                    fill
                    src={getStrapiMedia(logoColor?.url)}
                    alt={logoColor?.alternativeText ?? ''}
                    priority
                    loading="eager"
                  />
                </div>
              </Link>
              <LocaleSwitch navbarColor={navbarColor} />
            </div>

            <div className="hidden lg:flex items-center h-full">
              {navigationItems?.map(item =>
                item.groups?.length ? (
                  <NavDropdown
                    key={item.id}
                    label={item.label}
                    groups={item.groups}
                    variant={item.variant}
                    navbarColor={navbarColor}
                    cta={item.cta}
                    requestDemoProps={item.requestDemoProps}
                  />
                ) : (
                  <Link
                    key={item.id}
                    href={
                      item.path as
                        | '/'
                        | ({
                            pathname: '/'
                          } & Omit<UrlObject, 'pathname'>)
                    }
                    className={cn(
                      'px-5 py-1 cursor-pointer',
                      colorClasses[navbarColor].text
                    )}
                    hrefLang={locale}
                    target={item.target}
                  >
                    <Typography
                      text={item.label}
                      variant="body16"
                    />
                  </Link>
                )
              )}
            </div>

            <div className="flex items-center gap-8">
              <Button
                as="a"
                color={navbarColor === 'light' ? 'primary' : 'white'}
                label={logInCta?.btnText}
                href={logInCta?.link}
                target={logInCta?.target}
                variant={logInCta?.btnVariant}
                hrefLang={locale}
                className="flex-shrink-0 w-fit"
              />
              {cta && (
                <Button
                  as="a"
                  color={navbarColor === 'light' ? 'primary' : 'white'}
                  label={cta?.btnText}
                  href={cta?.link}
                  target={cta?.target}
                  variant={cta?.btnVariant}
                  hrefLang={locale}
                  withIcon
                  className="w-fit min-w-[160px] justify-between"
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default Navbar
