import React, { ReactNode } from 'react'
import { ArrowLeftIcon } from 'lucide-react'
import ChevronRight from '@/assets/icons/ChevronRight'
import DoubleChevronRightIcon from '@/assets/icons/ChevronRightDouble'
import { cn } from '@/ds/utils/cn'
import { Link } from '@/i18n/routing'
import { useLocale } from 'next-intl'

export type ButtonColor = 'primary' | 'white'
export type ButtonVariant = 'filled' | 'outlined' | 'onlyText'
export type ButtonType = 'button' | 'a'

type AnchorProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'onClick'
>
type ButtonHTMLProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
>
type ClickEvent = React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>

type BaseProps = {
  as?: ButtonType
  color?: ButtonColor
  variant?: ButtonVariant
  disabled?: boolean
  label: ReactNode
  className?: string
  withIcon?: boolean
  withBackIcon?: boolean
  onClick?: (event: ClickEvent) => void
  id?: string
}

export type ButtonProps = BaseProps &
  (({ as: 'a' } & AnchorProps) | ({ as?: 'button' } & ButtonHTMLProps))

const Button: React.FC<ButtonProps> = ({
  as = 'button',
  color = 'primary',
  variant = 'filled',
  id,
  label,
  className,
  withIcon = false,
  onClick,
  withBackIcon = false,
  ...props
}) => {
  const locale = useLocale()
  const colorClasses = {
    primary: {
      background: 'bg-primary',
      text: 'text-white',
      border: 'border-primary',
      textOutlined: 'text-primary',
      shadow: 'shadow-button-primary-filled',
    },
    white: {
      background: 'bg-white',
      text: 'text-primary',
      border: 'border-white',
      textOutlined: 'text-white',
      shadow: '',
    },
  }

  const variantClasses = {
    filled: `${colorClasses[color].background} ${colorClasses[color].text} ${colorClasses[color].shadow}`,
    outlined: `bg-transparent ${colorClasses[color].textOutlined} ${colorClasses[color].border} border`,
    onlyText: `bg-transparent ${colorClasses[color].textOutlined} gap-0`,
  }

  const hoverClasses = {
    primary: {
      filled:
        'hover:bg-gradient-primary focus-visible:bg-gradient-primary shadow-button-primary-filled',
      outlined: 'hover:bg-white focus-visible:bg-white',
      onlyText:
        'hover:text-gradient-primary focus-visible:text-gradient-primary',
    },
    white: {
      filled: 'hover:bg-white-80% focus-visible:bg-white-80%',
      outlined: 'hover:bg-white-outline focus-visible:bg-white-outline',
      onlyText: 'hover:text-cyan-lightest focus-visible:text-cyan-lightest',
    },
  }

  const finalClasses = cn(
    'inline-flex items-center justify-center rounded-md font-medium transition-all duration-300 relative group/button font-display gap-3',
    variantClasses[variant],
    hoverClasses[color][variant],
    withIcon ? 'px-3 py-2' : 'px-4 py-1',
    variant === 'onlyText' ? 'p-0' : '',
    'text-base font-bold',
    className
  )

  const IconWrapper = () => (
    <span
      className={cn('ml-2 inline-flex items-center relative', {
        'rotate-180': locale === 'ar',
      })}
    >
      <ChevronRight
        className={cn(
          'transition-opacity duration-300',
          'opacity-100',
          'group-hover/button:opacity-0'
        )}
        style={{ fill: 'inherit' }}
      />
      <DoubleChevronRightIcon
        className={cn(
          'absolute right-0 transition-opacity duration-300',
          'opacity-0',
          'group-hover/button:opacity-100'
        )}
      />
    </span>
  )

  const backIconWrapper = () => <ArrowLeftIcon className="w-4 h-4" />

  if (as === 'a') {
    const { href, target, rel, ...anchorProps } = props as AnchorProps
    if (!href) return null
    return (
      <Link
        key={locale + href}
        className={finalClasses}
        onClick={onClick}
        href={href as any}
        locale={locale}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : rel}
        hrefLang={locale}
        {...(id ? { id: id } : {})}
        {...anchorProps}
      >
        {withBackIcon && backIconWrapper()}
        {label}
        {withIcon && <IconWrapper />}
      </Link>
    )
  }

  const { type = 'button', disabled, ...buttonProps } = props as ButtonHTMLProps
  return (
    <button
      type={type}
      className={finalClasses}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      {withBackIcon && backIconWrapper()}
      {label}
      {withIcon && <IconWrapper />}
    </button>
  )
}

export default Button
