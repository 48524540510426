'use client'

// this function to help get cookie in client side
export function getCookie(name: string): string | null {
  if (typeof window !== 'undefined' && navigator.cookieEnabled) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts?.length === 2) {
      return parts?.pop()?.split(';').shift() || null
    }
  }
  return null
}
