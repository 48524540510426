/* eslint-disable react/prop-types */
const DoubleChevronRightIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    {...props}
  >
    <g id="chevron-right">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5771 4.41058C11.9025 4.08514 12.4302 4.08514 12.7556 4.41058L17.7556 9.41058C18.081 9.73602 18.081 10.2637 17.7556 10.5891L12.7556 15.5891C12.4302 15.9145 11.9025 15.9145 11.5771 15.5891C11.2516 15.2637 11.2516 14.736 11.5771 14.4106L15.9878 9.99984L11.5771 5.58909C11.2516 5.26366 11.2516 4.73602 11.5771 4.41058Z"
        fill="currentColor"
      />
      <path
        id="Icon_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9107 4.41058C5.23614 4.08514 5.76378 4.08514 6.08921 4.41058L11.0892 9.41058C11.4147 9.73602 11.4147 10.2637 11.0892 10.5891L6.08921 15.5891C5.76378 15.9145 5.23614 15.9145 4.9107 15.5891C4.58527 15.2637 4.58527 14.736 4.9107 14.4106L9.32145 9.99984L4.9107 5.58909C4.58527 5.26366 4.58527 4.73602 4.9107 4.41058Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default DoubleChevronRightIcon
