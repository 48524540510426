export const getParamsUTM = () => {
  if (typeof window !== 'undefined') {
    const paramsUTM = new URLSearchParams(window.location.search)

    const queryParams: Record<string, string | number> = {}
    paramsUTM?.forEach((value, key) => {
      queryParams[key] = value
    })

    if (paramsUTM.size !== 0) return queryParams
  }
  return null
}
