export const baseUrl = process.env.NEXT_PUBLIC_FE_URL
export const strapiBaseUrl = process.env.NEXT_PUBLIC_STRAPI_API_URL
export const strapiToken = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN
export const abTestingCookieAge = 60 * 60 * 24 * 90 // 90 days

export const isProductionBuild =
  process.env.NEXT_PUBLIC_SERVER_ENV === 'production'

const imageProps = {
  url: '/assets/img/department_1.png',
  alternativeText: 'JISR Logo',
  id: 0,
  documentId: '',
  name: '',
  caption: null,
  width: 0,
  height: 0,
  formats: {
    thumbnail: {
      ext: '',
      url: '',
      hash: '',
      mime: '',
      name: '',
      path: null,
      size: 0,
      width: 0,
      height: 0,
      sizeInBytes: 0,
    },
  },
  hash: '',
  ext: '',
  mime: '',
  size: 0,
  previewUrl: null,
  provider: '',
  provider_metadata: null,
  createdAt: '',
  updatedAt: '',
  publishedAt: '',
}

// Sample data structure
export const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 2,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Manufacturing',
    companySize: '1-50',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 3,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Healthcare',
    companySize: '201-500',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 4,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Energy and mining',
    companySize: '1000+',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 5,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Other',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 6,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 7,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 8,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 9,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 10,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 11,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 12,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
  {
    id: 13,
    name: 'John Doe',
    company: 'Mark Schools',
    companyLogo: {
      ...imageProps,
      url: 'https://ancient-car-b5a7f13d5c.media.strapiapp.com/thumbnail_image_5_ac20e09452.png',
      alternativeText: 'company logo',
    },
    industry: 'Education',
    companySize: '51-200',
    content:
      'Platform helped us gain valuable insights and make data-driven decisions.',
    image: {
      ...imageProps,
      url: 'https://i.ibb.co/pv03DFL/image-32.png',
      alternativeText: 'testimonial image',
    },
  },
]
