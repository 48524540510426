import PasswordCheckIcon from '@/assets/icons/password-check'
import { cn } from '@/ds/utils/cn'
import { Typography } from '@/ds/atoms/Typography'
import { getStrapiMedia } from '@/utils/media'
import { SubNavItem } from './navigation'
import Image from 'next/image'

export const NavListSubItem = ({ item }: { item: SubNavItem }) => {
  return (
    <div className="space-y-1 flex flex-col">
      <div className="space-x-1.5 flex items-center">
        {item.showIcon && (
          <div className="relative w-5 h-5 flex items-center">
            {item.icon ? (
              <Image
                src={getStrapiMedia(item.icon?.url)}
                alt={item.icon?.alternativeText}
                fill
                className="text-grays-light object-cover"
                priority
                loading="eager"
              />
            ) : (
              <PasswordCheckIcon className={cn('w-5 h-5 text-grays-light')} />
            )}
          </div>
        )}

        <Typography
          text={item.label}
          variant="body20"
          className={cn(
            'text-grays-bold',
            'group-hover/item:text-primary transition-colors'
          )}
        />
      </div>
      {item.description && (
        <Typography
          text={item.description}
          variant="body14"
          className={cn('text-grays-lighter', item.showIcon && 'ms-[26px]')}
        />
      )}
    </div>
  )
}
