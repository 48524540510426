import { strapiBaseUrl } from '@/constants'

export function getStrapiMedia(imageUrl: string): string {
  if (!imageUrl) return '' // or return a placeholder image

  // If the URL is already absolute, return it as is
  if (imageUrl?.startsWith('http')) {
    return imageUrl
  }

  // Otherwise, prepend the API URL
  return `${strapiBaseUrl}${imageUrl}`
}
