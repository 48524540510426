'use client'
import { Menu, X, ChevronRight } from 'lucide-react'
import { useState } from 'react'
import { Link } from '@/i18n/routing'
import { useLocale } from 'next-intl'
import { cn } from '@/ds/utils/cn'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import { Typography } from '@/ds/atoms/Typography'
import { NavigationItem } from './navigation'
import { MobileNavDropdown } from './MobileNavDropdown'
import LocaleSwitch from '../LocaleSwitch/LocaleSwitch'
import { MobileNavTabsDropdown } from './MobileNavTabsDropdown'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'
import Image from 'next/image'

type MobileSidebarProps = {
  items: NavigationItem[]
  cta?: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
  logo: StrapiImageComponent
  logInCta: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
  // navbarColor: 'dark' | 'light'
}

const MobileSidebar = ({
  items,
  cta,
  logo,
  logInCta,
  // navbarColor,
}: MobileSidebarProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isOpen, setIsOpen] = useState<NavigationItem | undefined>()
  const locale = useLocale()

  const onClose = () => {
    setIsSidebarOpen(false)
    setIsOpen(undefined)
  }

  const onSidebarToggle = () => {
    if (isSidebarOpen) onClose()
    else setIsSidebarOpen(true)
  }

  return (
    <>
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-white px-5 py-4 h-[72px]">
        <div className="flex justify-between items-center w-full">
          <Link
            hrefLang={locale}
            href="/"
            onClick={onClose}
          >
            <div className="relative h-8 w-20">
              <Image
                src={getStrapiMedia(logo?.url)}
                alt={logo?.alternativeText ?? ''}
                fill
                className="object-contain"
                priority
                loading="eager"
              />
            </div>
          </Link>
          <div className="flex gap-[28px]">
            {cta && (
              <Button
                as="a"
                // color={navbarColor === 'light' ? 'primary' : 'white'}
                color="primary"
                label={cta.btnText}
                href={cta.link}
                target={cta.target}
                variant={cta.btnVariant}
                withIcon
                hrefLang={locale}
              />
            )}
            <button
              onClick={onSidebarToggle}
              className="focus:outline-none"
            >
              {isSidebarOpen ? (
                <X
                  // className={`${navbarColor === 'light' ? 'text-primary' : 'text-white'}`}
                  className="text-primary"
                  height={32}
                  width={32}
                  strokeWidth={3}
                />
              ) : (
                <Menu
                  // className={`${navbarColor === 'light' ? 'text-primary' : 'text-white'}`}
                  className="text-primary"
                  height={32}
                  width={32}
                />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 z-40">
          <div
            role="presentation"
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={onClose}
          />
          <div
            className={cn(
              'fixed top-[72px] w-full bg-white rounded-bl-[32px] rounded-br-[32px]',
              {
                'right-0': locale === 'en',
                'left-0': locale === 'ar',
              }
            )}
          >
            <div className="flex flex-col">
              {/* Navigation Items */}
              <div className="flex flex-col py-8 px-5 max-h-[calc(100vh-72px)] overflow-y-auto">
                {!isOpen && (
                  <>
                    <div className="flex flex-col gap-4">
                      {items.map(item =>
                        item.groups?.length ? (
                          <button
                            key={item.id}
                            className="w-full flex items-center justify-between pb-[6px] border-b border-grays-bg"
                            onClick={() => {
                              setIsOpen(!isOpen ? item : undefined)
                            }}
                          >
                            <Typography
                              text={item.label}
                              variant="body20"
                              className="text-grays-bold"
                            />
                            <ChevronRight
                              className={cn(
                                'w-6 h-6 text-grays-bold',
                                locale === 'ar' && 'rotate-180'
                              )}
                            />
                          </button>
                        ) : (
                          <Link
                            hrefLang={locale}
                            key={item.id}
                            href={item.path as '/' | { pathname: '/' }}
                            onClick={onClose}
                            target={item.target}
                            className="border-b border-grays-bg pb-[6px]"
                          >
                            <Typography
                              text={item.label}
                              variant="body20"
                              className="text-grays-bold"
                            />
                          </Link>
                        )
                      )}
                    </div>

                    {/* Footer Actions */}
                    <div className="flex flex-col mt-10 gap-[28px]">
                      <LocaleSwitch navbarColor="light" />
                      <Button
                        as="a"
                        className="w-fit"
                        // color={navbarColor === 'light' ? 'primary' : 'white'}
                        color="primary"
                        label={logInCta.btnText}
                        href={logInCta.link}
                        target={logInCta.target}
                        variant={logInCta.btnVariant}
                        hrefLang={locale}
                      />
                      {cta && (
                        <Button
                          as="a"
                          hrefLang={locale}
                          className="w-fit"
                          // color={navbarColor === 'light' ? 'primary' : 'white'}
                          color="primary"
                          label={cta.btnText}
                          href={cta.link}
                          target={cta.target}
                          variant={cta.btnVariant}
                          withIcon
                        />
                      )}
                    </div>
                  </>
                )}
                {isOpen && (
                  <>
                    {isOpen.variant !== 'section' ? (
                      <MobileNavDropdown
                        item={isOpen}
                        onClose={onClose}
                      />
                    ) : (
                      <>
                        {isOpen?.groups?.[0] && (
                          <MobileNavTabsDropdown
                            group={isOpen.groups[0]}
                            onClose={onClose}
                          />
                        )}
                      </>
                    )}

                    {/* Footer Actions */}
                    <div className="flex flex-col mt-10 pb-8">
                      {
                        <Button
                          as="button"
                          label={'Main Menu'}
                          variant="onlyText"
                          onClick={() => {
                            setIsOpen(undefined)
                          }}
                          className="w-fit"
                          withBackIcon
                        />
                      }
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MobileSidebar
