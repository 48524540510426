import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * A utils function that combines Tailwind CSS classes and the `clsx` library, with the ability to merge classes.
 * @param classes - An array of class names or conditions.
 * @returns A string of class names.
 */
export const cn = (...classes: ClassValue[]) => {
  return twMerge(clsx(...classes))
}
