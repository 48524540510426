import { cn } from '@/ds/utils/cn'
import React from 'react'

// Define the new component
const Container = ({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        'mx-auto w-full max-w-screen-2xl px-5 py-16 md:p-[50px] lg:p-[100px]',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Container
