/* eslint-disable react/prop-types */
import React from 'react'

const PasswordCheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    {...props}
  >
    <g id="vuesax/bulk/password-check">
      <g id="vuesax/bulk/password-check_2">
        <g id="password-check">
          <path
            id="Vector"
            d="M15 22.5005C14.59 22.5005 14.25 22.1605 14.25 21.7505V3.25049C14.25 2.84049 14.59 2.50049 15 2.50049C15.41 2.50049 15.75 2.84049 15.75 3.25049V21.7505C15.75 22.1605 15.41 22.5005 15 22.5005Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            opacity="0.4"
            d="M6.49841 20.4995H12.4984V4.49951H6.49841C4.28841 4.49951 2.49841 6.28951 2.49841 8.49951V16.4995C2.49841 18.7095 4.28841 20.4995 6.49841 20.4995Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            opacity="0.4"
            d="M18 20.4995H15V4.49951H18C20.21 4.49951 22 6.28951 22 8.49951V16.4995C22 18.7095 20.21 20.4995 18 20.4995Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M5.74841 13.5001C5.61841 13.5001 5.48841 13.4701 5.36841 13.4201C5.24841 13.3701 5.13841 13.3001 5.03841 13.2101C4.94841 13.1101 4.87841 13.0001 4.81841 12.8801C4.76841 12.7601 4.74841 12.6301 4.74841 12.5001C4.74841 12.2401 4.85841 11.9801 5.03841 11.7901C5.08841 11.7501 5.13841 11.7101 5.18841 11.6701C5.24841 11.6301 5.30841 11.6001 5.36841 11.5801C5.42841 11.5501 5.48841 11.5301 5.54841 11.5201C5.88841 11.4501 6.22841 11.5601 6.45841 11.7901C6.63841 11.9801 6.74841 12.2401 6.74841 12.5001C6.74841 12.6301 6.71841 12.7601 6.66841 12.8801C6.61841 13.0001 6.54841 13.1101 6.45841 13.2101C6.35841 13.3001 6.24841 13.3701 6.12841 13.4201C6.00841 13.4701 5.87841 13.5001 5.74841 13.5001Z"
            fill="currentColor"
          />
          <path
            id="Vector_5"
            d="M9.25 13.4997C9.12 13.4997 8.99 13.4697 8.87 13.4197C8.75 13.3697 8.64 13.2997 8.54 13.2097C8.35 13.0197 8.25 12.7697 8.25 12.4997C8.25 12.3697 8.28 12.2397 8.33 12.1197C8.38 11.9897 8.45 11.8897 8.54 11.7897C8.91 11.4197 9.58 11.4197 9.96 11.7897C10.14 11.9797 10.25 12.2397 10.25 12.4997C10.25 12.6297 10.22 12.7597 10.17 12.8797C10.12 12.9997 10.05 13.1097 9.96 13.2097C9.86 13.2997 9.75 13.3697 9.63 13.4197C9.51 13.4697 9.38 13.4997 9.25 13.4997Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default PasswordCheckIcon
